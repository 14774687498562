import consumer from './consumer'

consumer.subscriptions.create({ channel: 'NotificationChannel' }, {
  received (data) {
    console.log('notifications_socket_message', data.message)
    console.log('notifications_socket_sum', data.sum)

    const content = document.getElementById('notifications-dropdown-content')
    const counter = document.getElementById('notifications-navbar-counter')

    if (data.sum > 0) {
      counter.innerText = data.sum
      counter.classList.remove('hidden')
    } else {
      counter.classList.add('hidden')
    }
    content.innerHTML = data.message
  }
})
